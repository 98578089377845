import set from "lodash/set";
import jQuery from "jquery";

set(
  App,
  "Navigation",
  (function ($) {
    const PROFILE_NAVIGATION_SELECTOR = "#j-ProfileNavigation";
    const PROFILE_OFFCANVAS_SELECTOR = "#j-ProfileOffcanvas";

    function injectNavigation() {
      var $profileNavigation = $(PROFILE_NAVIGATION_SELECTOR);
      var $profileOffcanvas = $(PROFILE_OFFCANVAS_SELECTOR);

      return $.ajax({
        url: "/_includes/navigation",
        method: "GET",
        success: function (response) {
          var $response = $("<div>").html($(response));

          $profileNavigation.html(
            $response.find(PROFILE_NAVIGATION_SELECTOR).contents()
          );
          $profileOffcanvas.html(
            $response.find(PROFILE_OFFCANVAS_SELECTOR).contents()
          );
        },
      });
    }

    return {
      init: function () {
        injectNavigation();
      },
    };
  })(jQuery)
);

// on ready, becase of django admin panel
jQuery(function () {
  App.Navigation.init();
});
