import noop from "lodash/noop";

import { userData } from "../../../js/shared/blocks/user-data";
import documentReady from '../../../js/shared/utils/document-ready';

export class CompanyHeaderActions {
  blockReviewButtons() {
    var reviewsButtonsToUpdate = document.querySelectorAll("[data-review-company-id]");

    reviewsButtonsToUpdate.forEach(function (button) {
      button.setAttribute("disabled", "");
      button.classList.add('disabled');
      button.removeAttribute("href");
    });
  }

  init(data) {
    if (!data.isAuthenticated) {
      return;
    }

    if (!data.isCandidate) {
      this.blockReviewButtons();
    }
  }
}

export const companyHeaderActions = new CompanyHeaderActions();
documentReady(() => {
  userData.getData().then(data => {
    companyHeaderActions.init(data);
  }).catch((noop));
});
