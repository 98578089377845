import noop from "lodash/noop";

import loadScript from '../utils/load-script';
import { userData } from "./user-data";
import { rodo } from '../../../shared/components/rodo/rodo';
import documentReady from '../utils/document-ready';

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

export class Analytics {
  constructor(analyticsId) {
    this.analyticsId = analyticsId;
  }

  attachEvents() {
    const ANALYTICS_SELECTOR = "data-analytics";

    document.addEventListener('click', function (e) {
      if (e.target && e.target.hasAttribute(ANALYTICS_SELECTOR)) {
        const data = JSON.parse(e.target.getAttribute(ANALYTICS_SELECTOR));

        const [eventType, eventName, eventData] = data;
        if (eventType && eventName && eventData) {
          gtag(eventType, eventName, eventData);
        }
      }
    });
  }

  load() {
    gtag('js', new Date());
    gtag('config', this.analyticsId);
    gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });

    loadScript("https://www.googletagmanager.com/gtag/js?id=" + this.analyticsId);

    userData.getData().then(data => {
      if (!data.user || !data.user.id) {
        return
      }

      this.setUserId(data.user.id);
    }).catch(noop);
  }

  setUserId(userId) {
    gtag({
      'event': 'login',
      'user_id': userId
    })
  }

  init() {
    if (!this.analyticsId) {
      return;
    }

    this.load();
    this.attachEvents();
  }
}

export const analytics = new Analytics(App.Contants.ANALYTICS_ID);

documentReady(() => {
  rodo.acceptedPromise.then(() => {
    analytics.init();
  }).catch(noop)
});
