"use strict";

// 3rd party
import UIkit from "uikit";
import SVGInject from "@iconfu/svg-inject";

// shared blocks
import "./shared/blocks/analytics";
import "./shared/blocks/tawk-to";
import "../shared/components/rodo/rodo";

// blocks
import "./blocks/navigation";
import "../shared/components/autocomplete/autocomplete";
import "./blocks/listing";
import "../public/components/company_header/company_header_actions";
import "../public/components/social_share/social_share";

// pages
import "./pages/company/company-index";

SVGInject(document.getElementsByClassName("js-SvgInject"));
document.addEventListener("DOMContentLoaded", function () {
  SVGInject(document.getElementsByClassName("js-SvgInject"));
});
