import each from "lodash/each";
import noop from "lodash/noop";
import * as $ from 'jquery';

import { userData } from "../shared/blocks/user-data";
import documentReady from '../shared/utils/document-ready';

export class Listing {
  updateApplyButtons(appliedListings) {
    each(appliedListings, (listing => {
      var listingId = listing[0];
      var locationId = listing[1];
      var $listingsButtonsToUpdate = $(
        "[data-apply-listing-id=" +
        listingId +
        "][data-apply-listing-location=" +
        locationId +
        "]"
      );

      $listingsButtonsToUpdate.find(".button-text").text("Zaaplikowane");

      if (!$listingsButtonsToUpdate.hasClass("external")) {
        $listingsButtonsToUpdate.addClass("s-ApplyLink-Disabled");
        $listingsButtonsToUpdate
          .find(".fal")
          .addClass("fa-check-circle")
          .removeClass("fa-check");
      }
    }));
  }

  blockApplyButtons() {
    var $listingsButtonsToUpdate = $("[data-apply-listing-id]");
    $listingsButtonsToUpdate.addClass("s-ApplyLink-Disabled");
    $listingsButtonsToUpdate.removeAttr("href");
  }

  init(data) {
    if (!data.isAuthenticated) {
      return;
    }

    if (data.isCandidate) {
      this.updateApplyButtons(data.candidate.appliedListings);
    } else {
      this.blockApplyButtons();
    }
  }
}

export const listing = new Listing();
documentReady(() => {
  userData.getData().then(data => {
    listing.init(data);
  }).catch((noop));
});
