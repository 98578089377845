import '../utils/polyfills';

export class UserData {
  constructor() {
    this.dataPromise = null;

    this.getData();
  }

  fetchData() {
    return fetch("/_includes/user")
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data.');
        }

        return response.json()
      })
  }

  getData() {
    if (!this.dataPromise) {
      this.dataPromise = this.fetchData();
    }

    return this.dataPromise;
  }
}

export const userData = new UserData();
