import '../../../js/shared/utils/polyfills';
import storageAvailable from 'storage-available'
import documentReady from '../../../js/shared/utils/document-ready';

export class Rodo {
  constructor() {
    this.acceptedPromise = new Promise((resolve, reject) => {
      this.acceptedPromiseResolve = resolve;
      this.acceptedPromiseReject = reject;
    });
  }

  init(root) {
    this.root = root;

    this.acceptButton = this.root.querySelector('[is="rodo-banner-accept"]');
    this.declineButton = this.root.querySelector('[is="rodo-banner-decline"]');

    this.bindEvents();

    if (!storageAvailable('localStorage')) {
      return;
    }

    if (this.isAccepted()) {
      this.acceptedPromiseResolve();
    } else {
      this.show();
    }
  }

  bindEvents() {
    this.acceptButton && this.acceptButton.addEventListener('click', this.accept.bind(this));
    this.declineButton && this.declineButton.addEventListener('click', this.decline.bind(this));
  }

  isAccepted() {
    return localStorage.getItem('rodo') === 'accepted'
  }

  accept(e) {
    e.preventDefault();

    localStorage.setItem('rodo', 'accepted');

    this.hide();

    this.acceptedPromiseResolve();
  }

  decline(e) {
    e.preventDefault();

    this.hide();
  }

  show() {
    if (!this.root) {
      return;
    }

    this.root.classList.add('show');
  }

  hide() {
    if (!this.root) {
      return;
    }

    this.root.classList.remove('show');
  }
}

export const rodo = new Rodo();
documentReady(() => {
  const root = document.querySelector('[is="rodo-banner"]');
  rodo.init(root);
});
