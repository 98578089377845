import set from "lodash/set";
import jQuery from "jquery";

set(
  App,
  "Blocks.SocialShare",
  (function ($) {
    /**
     * jQuery function to prevent default anchor event and take the href * and the title to make a share popup
     *
     * @param  {[object]} e           [Mouse event]
     * @param  {[int]} intWidth   [Popup width default 500]
     * @param  {[int]} intHeight  [Popup height default 400]
     * @param  {[boolean]} blnResize  [Is popup resizeable default true]
     */
    $.fn.customerPopup = function (e, intWidth, intHeight, blnResize) {
      // Prevent default anchor event
      e.preventDefault();

      // Set values for window
      var width = intWidth || "500";
      var height = intHeight || "400";
      var strResize = blnResize ? "yes" : "no";

      // Set title and open popup with focus on it
      var strTitle =
        typeof this.attr("title") !== "undefined"
          ? this.attr("title")
          : "Social Share",
        strParam =
          "width=" + width + ",height=" + height + ",resizable=" + strResize,
        objWindow = window.open(this.attr("href"), strTitle, strParam).focus();
    };

    /* ================================================== */

    return {
      init: function () {
        $(document).on(
          "click",
          ".js-SocialShare a.js-SocialShare_link",
          function (e) {
            $(this).customerPopup(e);
          }
        );
      },
    };
  })(jQuery)
);

App.Blocks.SocialShare.init();
