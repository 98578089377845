import select2 from "select2";
import set from "lodash/set";
import jQuery from "jquery";

/**
 * SuggestionWidget (Location) and MultiSuggestionWidget (Skills)
 */
set(
  App,
  "Forms.SuggestionWidgets",
  (function ($) {
    var CLASS_NAME = ".suggestion-widget";

    function markMatch(item, term, types) {
      // Find where the match is
      var match = item.text.toUpperCase().indexOf(term.toUpperCase());

      var $result = $("<span></span>");

      // If there is no match, move on
      if (match < 0) {
        return $result.text(item.text);
      }

      // Put in whatever text is before the match
      $result.text(item.text.substring(0, match));

      // Mark the match
      var $match = $('<span class="select2-rendered__match"></span>');
      $match.text(item.text.substring(match, match + term.length));

      // Append the matching text
      $result.append($match);

      // Put in whatever is after the match
      $result.append(item.text.substring(match + term.length));

      if (types.length > 1) {
        $result.append(
          ' <span class="select2-rendered__type">(' + item.type + ")</span>"
        );
      }

      return $result;
    }

    function initialize() {
      $(CLASS_NAME).each(function () {
        var query = {};
        var $suggestionWidget = $(this);
        var types = JSON.parse($(this).data("types").replace(/[']/g, '"')).map(
          function (type) {
            return "type=" + type;
          }
        );
        var prefixed = $(this).data("prefixed");

        if (prefixed) {
          types.push("prefixed=on");
        }
        var url = "/tag/autocomplete/?" + types.join("&");

        $suggestionWidget.select2({
          templateResult: function (item) {
            // No need to template the searching text
            if (item.loading) {
              return item.text;
            }

            var term = query.term || "";
            var $result = markMatch(item, term, types);

            return $result;
          },
          templateSelection: function (s, container) {
            if (s.tagType) {
              container.addClass("n-Tag-" + s.tagType);
            }
            return s.text;
          },
          createTag: function (params) {
            var term = $.trim(params.term);

            if (term === "") {
              return null;
            }

            return {
              id: "keyword:" + term,
              type: "Zawierające frazę",
              text: term,
              newTag: true, // add additional parameters
            };
          },
          dropdownAutoWidth: true,
          width: "100%",
          language: {
            searching: function (params) {
              // Intercept the query as it is happening
              query = params;

              // Change this to be appropriate for your application
              return "Ładowanie…";
            },
          },
          ajax: {
            url: url,
            dataType: "json",
            delay: 250,
            data: function (params) {
              return {
                q: params.term, // search term
                page: params.page,
              };
            },
          },
        });
      });
    }

    function close() {
      $(CLASS_NAME).each(function () {
        $(this).select2("close");
      });
    }

    return {
      init: function () {
        initialize();

        $(document).on("page:loading", close);
      },
    };
  })(jQuery)
);

// on ready, becase of django admin panel
jQuery(function () {
  App.Forms.SuggestionWidgets.init();
});
