import set from "lodash/set";
import jQuery from "jquery";

set(
  App,
  "Pages.Company.CompanySearch",
  (function (window, $) {
    var CONTENT_SELECTOR = "body",
      FORM_SELECTOR = "#company-search-form";

    function onChangeSubmit() {
      $(document).on("change", FORM_SELECTOR, function (e) {
        const $target = $(e.target);

        // Select2 contains hidden search text input, which triggers form change
        // before adding selected option. Prevent this change triggering the form submission
        if (!$target.attr("id")) {
          return;
        }

        $(this).submit();
      });
    }

    function markLoadingOnUnload() {
      window.onbeforeunload = function () {
        var $content = $(CONTENT_SELECTOR);

        $(document).trigger("page:loading");
        $content.addClass("c-Page-loading");
      };
    }
    return {
      init: function () {
        if (!$("body").hasClass("s-PageCompanyIndex")) {
          return;
        }

        onChangeSubmit();
        markLoadingOnUnload();
      },
    };
  })(window, jQuery)
);

// on ready, becase of django admin panel
jQuery(function () {
  App.Pages.Company.CompanySearch.init();
});
