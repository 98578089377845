import noop from "lodash/noop";

import { userData } from "./user-data";
import loadScript from '../utils/load-script';
import { rodo } from '../../../shared/components/rodo/rodo';
import documentReady from '../utils/document-ready';

const TAWK_TO_INITIALISED_EVENT = 'tawk-to:initialised';

export class TawkTo {
  constructor(propertyId, widgetId) {
    this.propertyId = propertyId;
    this.widgetId = widgetId;
  }

  initialiseWidget(userData) {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    if (userData.user && userData.tawkto) {
      window.Tawk_API.visitor = {
        name: `${userData.user.firstName} ${userData.user.lastName}`,
        email: userData.user.email,
        hash: userData.tawkto.hash,
      };
    }

    return loadScript(`https://embed.tawk.to/${this.propertyId}/${this.widgetId}`, { crossorgin: '*' }).then(() => {
      document.dispatchEvent(new CustomEvent(TAWK_TO_INITIALISED_EVENT));
    });
  }

  isRecruiterSection() {
    return window.location.pathname.indexOf('/rekruter/') === 0;
  }

  init() {
    if (!this.propertyId || !this.widgetId) {
      return;
    }

    userData.getData().then(data => {
      if (data.isRecruiter || (!data.isAuthenticated && this.isRecruiterSection())) {
        this.initialiseWidget(data);
      }
    }).catch(noop);
  }
}

export const tawkTo = new TawkTo(
  App.Contants.TAWKTO_PROPERTY_ID,
  App.Contants.TAWKTO_WIDGET_ID,
);

documentReady(() => {
  rodo.acceptedPromise.then(() => {
    tawkTo.init();
  }).catch(noop);
});
